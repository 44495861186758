import React, { useEffect, useState } from 'react';
import './App.css';
import { Button, Layout } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainSection from './components/MainView/MainSection';
import logo from './icons/favicon.ico';
import applepickerlogo from './images/applepicker-logo.png';
import { changeMode, selectMode } from './Redux/roundsSlice';

const { Header } = Layout;

function inIframe() {
  try {
    if (
      window.self !== window.top
      || window.location.href === 'http://localhost:3000/'
    ) {
      return true;
    }
    return false;
  } catch (err) {
    return true;
  }
}

function App() {
  const [iframe, setIframe] = useState(false);
  const dispatch = useDispatch();
  const mode = useSelector(selectMode);

  useEffect(() => {
    setIframe(inIframe);
  }, []);

  if (!iframe) {
    return (
      <div>
        You are not properly authenticated. Please login through
        {' '}
        <a href="https://portal2.nymets.com/apple-picker">
          the following link
        </a>
      </div>
    );
  }

  let buttonTypeStats = 'primary';
  let buttonTypeResults = 'default';
  if (mode === 'table') {
    buttonTypeStats = 'primary';
    buttonTypeResults = 'default';
  } else if (mode === 'board') {
    buttonTypeStats = 'default';
    buttonTypeResults = 'primary';
  }

  return (
    <BrowserRouter>
      <Layout>
        <Header className="applepicker-header">
          <img
            className="logo"
            src={logo}
            alt="logo"
          />
          <img
            className="applepicker-logo"
            src={applepickerlogo}
            alt="Apple Picker logo"
          />
          <div className="modeSwitch">
            <Button type={buttonTypeStats} onClick={() => dispatch(changeMode('table'))}>Stats</Button>
            <Button type={buttonTypeResults} onClick={() => dispatch(changeMode('board'))}>Results</Button>
          </div>
        </Header>
        <MainSection />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
