import React from 'react';
import { Card, Row, Col } from 'antd';
import './PlayerFocusCard.css';
import { useSelector } from 'react-redux';
import { selectFocus } from '../../Redux/focusSlice';
import getAge from '../../helpers/misc';
import PlayerMagnet from '../PlayerMagnet/PlayerMagnet';
import profile from '../../pictures/profile.PNG';

// Top-level component for the player focus
// Displays all relevant data associated with a given playesr
function PlayerFocusCard() {
  const focus = useSelector(selectFocus);
  let battingSideAbbr = '';
  let throwingSideAbbr = '';
  // let statusCheck = '';
  let profileLink = profile;
  // let agentName = 'None Known!';
  // let agentPhone = 'None Known!';
  if (focus !== '') {
    // eslint-disable-next-line prefer-destructuring
    battingSideAbbr = focus.battingSide[0];
    // eslint-disable-next-line prefer-destructuring
    throwingSideAbbr = focus.throwingSide[0];
    // statusCheck = focus.Status;
    /*
    if (focus['contacts.name'] !== null) {
      agentName = focus['contacts.name'];
    }
    if (focus.phone !== null) {
      agentPhone = focus.phone;
    }
    */
    if (focus.filename !== null) {
      profileLink = `https://files.nymets.com/media/amateur/Images/Headshots/${focus.filename}`;
    } else {
      profileLink = profile;
    }
  }

  return (
    <Card className="playerFocusCard">
      <Row>
        <Col span={1}>
          <img className="profile" src={profileLink} alt="profile" />
        </Col>
        <Col span={10}>
          <Row gutter={16}>
            <Col span={24}>
              <div className="playerFocusTitle">
                {focus.playerName}
                ,
                {' '}
                {focus.Pos || focus.position}
                ,
                {' '}
                {focus.schoolName}
                {' '}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div className="playerFocusTextBold">
                Age:
                {' '}
                {getAge(focus.dateOfBirthAsString)}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                B/T:
                {' '}
                {(`${focus.Bats || battingSideAbbr}/${focus.Thr || throwingSideAbbr}`)}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                Role/Prob:
                {' '}
                {`${focus.Role?.toFixed(0) || '--'}/${focus.Prob?.toFixed(0) || '--'}`}
              </div>
            </Col>
            <Col span={8}>
              <div className="playerFocusText">
                Agent Name:
                {' '}
                {focus['contacts.name'] || 'None Known!'}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div className="playerFocusTextBold">
                Class:
                {' '}
                {focus.schoolClass}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                Height:
                {' '}
                {`${focus.heightFeet}' ${focus.heightInches}"`}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                STATS:
                {' '}
                {focus.Perf?.toFixed(1) || '--'}
              </div>
            </Col>
            <Col span={6}>
              <div className="playerFocusText">
                Agent #:
                {' '}
                {focus.phone || 'None Known!'}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div className="playerFocusTextBold">
                Draft ID:
                {' '}
                {focus.draftId || focus.playerId}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                Weight:
                {' '}
                {focus.weight || '--'}
              </div>
            </Col>
            <Col span={5}>
              <div className="playerFocusText">
                S
                {' '}
                &
                {' '}
                S:
                {' '}
                {focus.Value?.toFixed(1) || '--'}
              </div>
            </Col>
            <Col span={9}>
              <div className="playerFocusText">
                Area Scout:
                {' '}
                {focus.area_scout || '--'}
              </div>
            </Col>

          </Row>
        </Col>
        <Col span={13}>
          <PlayerMagnet />
        </Col>
      </Row>
    </Card>
  );
}

export default PlayerFocusCard;
