import React from 'react';
import { Card } from 'antd';
import PlayerTable from './PlayerTable';
import './PlayerTableCard.css';

// Top-level component for the player table which houses a list
// of draftable players which can be filtered from the filter bar
function PlayerTableCard() {
  return (
    <Card className="playerTableCard">
      <PlayerTable />
    </Card>
  );
}

export default PlayerTableCard;
