import React from 'react';
import { Space } from 'antd';
import { useSelector } from 'react-redux';
import DraftBoardCard from './DraftBoardCard';
import { selectRounds } from '../../Redux/roundsSlice';
import teams from '../../helpers/teamMap';

// A draft round with all associated picks
function DraftRound(vals) {
  const rounds = useSelector(selectRounds);
  const round = [];

  for (let i = 0; i < rounds[vals.round].length; i += 1) {
    round.push(
      <div
        className={`board_round_${vals.round}_pick_${i}`}
        id={`board_round_${vals.round}_pick_${i}`}
      >
        <DraftBoardCard
          round={vals.round}
          roundName={rounds[vals.round][i].roundCode}
          pick={rounds[vals.round][i].pickInRound}
          teamName={teams[rounds[vals.round][i].orgId]}
          totalPick={rounds[vals.round][i].overallPickNumber}
          desc={rounds[vals.round][i].roundCode}
        />
      </div>,
    );
  }

  return (
    React.Children.map(round, (j) => (
      <Space direction="horizontal">
        {j}
      </Space>
    ))
  );
}

export default DraftRound;
