import React from 'react';
import { useSelector } from 'react-redux';
import { selectFocus } from '../../Redux/focusSlice';
import PlayerMagnetPitcher from './PlayerMagnetPitcher';
import PlayerMagnetHitter from './PlayerMagnetHitter';

// Top-level component for player magnet
// Replicates the magnets used by scouting
// Pitchers and hitters have different magnets
function PlayerMagnet() {
  const focus = useSelector(selectFocus);
  if (focus !== '') {
    if (focus.Pos === 'SP' || focus.Pos === 'RP' || focus.Pos === 'RHS'
     || focus.Pos === 'LHS' || focus.Pos === 'RHR' || focus.Pos === 'LHR'
      || focus.position === 'SP' || focus.position === 'RP' || focus.position === 'RHS'
      || focus.position === 'LHS' || focus.position === 'RHR' || focus.position === 'LHR') {
      return (
        <PlayerMagnetPitcher />
      );
    }

    return (
      <PlayerMagnetHitter />
    );
  }
}

export default PlayerMagnet;
