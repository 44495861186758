const teams = {
  1: 'ARI',
  2: 'ATL',
  3: 'BAL',
  4: '',
  5: 'BOS',
  6: 'CHC',
  7: 'CIN',
  8: 'CLE',
  9: 'COL',
  10: 'CWS',
  11: 'DET',
  12: 'HOU',
  13: 'KC',
  14: 'LAD',
  15: 'LAA',
  16: 'MIA',
  17: 'MIL',
  18: 'MIN',
  19: '',
  20: 'NYM',
  21: 'NYY',
  22: 'OAK',
  23: 'PHI',
  24: 'PIT',
  25: 'SDP',
  26: 'SEA',
  27: 'SFG',
  28: 'STL',
  29: 'TBR',
  30: 'TEX',
  31: 'TOR',
  32: 'WSH',
};

export default teams;
