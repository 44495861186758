import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { selectFocus } from '../../Redux/focusSlice';
import './PlayerMagnetPitcher.css';

// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const
let fbg, cbg, slg, chg, fbv, fbs, cbs, sls, chs, fbc, cbc, slc, chc = '';
// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const
let fbsm, cbsm, slsm, chsm, fbgb, cbgb, slgb, chgb, fbext = '';
// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const
let yrBB, yrK, yrISO, yrGB, carBB, carK, carISO, carGB, ath, mkp = '';

// Conditional formatting, green is good, yellow is ok, red is bad
function conditionalFormatting(input, low, high) {
  if (input === null) {
    return 'statUnconditionalPitcher';
  }
  if (low < high) {
    if (input <= low) {
      return 'redStylePitcher';
    }
    if (input > low && input <= high) {
      return 'yellowStylePitcher';
    }
    if (input > high) {
      return 'greenStylePitcher';
    }
  } else if (low > high) {
    if (input > high) {
      return 'redStylePitcher';
    }
    if (input <= high && input > low) {
      return 'yellowStylePitcher';
    }
    if (input <= low) {
      return 'greenStylePitcher';
    }
  }
  return 'statUnconditionalPitcher';
}
// Replicates the magnets for pitchers used by scouting
function PlayerMagnetPitcher() {
  const focus = useSelector(selectFocus);
  const magnetVars = [
    {
      stat: fbg,
      input: focus.FB,
      low: 44,
      high: 55,
    },
    {
      stat: cbg,
      input: focus.CB,
      low: 44,
      high: 55,
    },
    {
      stat: slg,
      input: focus.SL,
      low: 44,
      high: 55,
    },
    {
      stat: chg,
      input: focus.CH,
      low: 44,
      high: 55,
    },
    {
      stat: fbv,
      input: focus.fbV,
      low: 90,
      high: 94,
    },
    {
      stat: fbs,
      input: focus['fb spin'],
      low: 1950,
      high: 2350,
    },
    {
      stat: cbs,
      input: focus['cb spin'],
      low: 2000,
      high: 2500,
    },
    {
      stat: sls,
      input: focus['sl spin'],
      low: 2000,
      high: 2500,
    },
    {
      stat: chs,
      input: focus['ch spin'],
      low: 1600,
      high: 1900,
    },

    {
      stat: fbc,
      input: focus.fbCmdF,
      low: 44,
      high: 55,
    },
    {
      stat: cbc,
      input: focus.cbCmdF,
      low: 44,
      high: 55,
    },
    {
      stat: slc,
      input: focus.slCmdF,
      low: 44,
      high: 55,
    },
    {
      stat: chc,
      input: focus.chCmdF,
      low: 44,
      high: 55,
    },
    {
      stat: fbsm,
      input: focus['fb sm'],
      low: 18,
      high: 22,
    },
    {
      stat: cbsm,
      input: focus['cb sm'],
      low: 25,
      high: 30,
    },
    {
      stat: slsm,
      input: focus['sl sm'],
      low: 30,
      high: 35,
    },
    {
      stat: chsm,
      input: focus['ch sm'],
      low: 26,
      high: 32,
    },
    {
      stat: fbgb,
      input: focus['fb gb'],
      low: 26,
      high: 34,
    },
    {
      stat: cbgb,
      input: focus['cb gb'],
      low: 39,
      high: 47,
    },
    {
      stat: slgb,
      input: focus['sl gb'],
      low: 35,
      high: 43,
    },
    {
      stat: chgb,
      input: focus['ch gb'],
      low: 45,
      high: 55,
    },
    {
      stat: fbext,
      input: focus['FB ext'],
      low: 6,
      high: 6.4,
    },
    {
      stat: yrBB,
      input: focus.BB,
      low: 12,
      high: 7,
    },
    {
      stat: yrK,
      input: focus.K,
      low: 20,
      high: 27,
    },
    {
      stat: yrISO,
      input: focus['22iso'],
      low: 0.15,
      high: 0.075,
    },
    {
      stat: yrGB,
      input: focus['22gb'],
      low: 44,
      high: 55,
    },
    {
      stat: carBB,
      input: focus.Carbb,
      low: 12,
      high: 7,
    },
    {
      stat: carK,
      input: focus.Cark,
      low: 20,
      high: 27,
    },
    {
      stat: carISO,
      input: focus.Cariso,
      low: 0.15,
      high: 0.075,
    },
    {
      stat: carGB,
      input: focus.Cargb,
      low: 45,
      high: 55,
    },
    {
      stat: ath,
      input: focus.Ath,
      low: 45,
      high: 55,
    },
    {
      stat: mkp,
      input: focus.Mkp,
      low: 45,
      high: 55,
    },
  ];
  magnetVars.forEach((magnetVar) => {
    // eslint-disable-next-line no-param-reassign
    magnetVar.stat = conditionalFormatting(magnetVar.input, magnetVar.low, magnetVar.high);
  });
  const IPOuts = focus.IP?.toFixed(1).toString();
  let outs = 0;
  let ipFinal = '--';
  // Converts IP from decimal to tertiary (5.1, 5.2 IP)
  if (focus.IP !== null) {
    // lazy conversion to ternary
    if (IPOuts[IPOuts.length - 1] === '3') {
      outs = 1;
    } else if (IPOuts[IPOuts.length - 1] === '6' || IPOuts[IPOuts.length - 1] === '7') {
      outs = 2;
    }
    ipFinal = parseFloat(`${Math.trunc(focus.IP)}.${outs}`);
  }
  return (
    <Row>
      <Col span={11}>
        <Row>
          <Col span={3} className="statNamePitcher">YR</Col>
          <Col span={3} className="statNamePitcher">IP</Col>
          <Col span={3} className="statNamePitcher">BB%</Col>
          <Col span={3} className="statNamePitcher">K%</Col>
          <Col span={3} className="statNamePitcher">ISO</Col>
          <Col span={3} className="statNamePitcher">GB%</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">22</Col>
          <Col span={3} className="statUnconditionalPitcher">{ipFinal}</Col>
          <Col span={3} className={magnetVars[22].stat}>{(focus.BB * 100).toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[23].stat}>{(focus.K * 100).toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[24].stat}>{focus['22iso'] || '--'}</Col>
          <Col span={3} className={magnetVars[25].stat}>{focus['22gb'] || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">Car</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.Carip || '--'}</Col>
          <Col span={3} className={magnetVars[26].stat}>{focus.Carbb || '--'}</Col>
          <Col span={3} className={magnetVars[27].stat}>{focus.Cark || '--'}</Col>
          <Col span={3} className={magnetVars[28].stat}>{focus.Cariso || '--'}</Col>
          <Col span={3} className={magnetVars[29].stat}>{focus.Cargb || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statNamePitcher">FB Ext</Col>
          <Col span={3} className="statNamePitcher">Ath</Col>
          <Col span={3} className="statNamePitcher">Mkp</Col>
          <Col span={3} className="statNamePitcher">Med</Col>
        </Row>
        <Row>
          <Col span={3} className={magnetVars[21].stat}>{focus['FB ext']?.toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[30].stat}>{focus.Ath?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[31].stat}>{focus.Mkp?.toFixed(0) || '--'}</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.Medical?.toFixed(0) || '--'}</Col>
        </Row>
      </Col>
      <Col span={1} />
      <Col span={11}>
        <Row>
          <Col span={3} className="statNamePitcher">Pitch</Col>
          <Col span={3} className="statNamePitcher">Grade</Col>
          <Col span={3} className="statNamePitcher">Velo</Col>
          <Col span={3} className="statNamePitcher">Spin</Col>
          <Col span={3} className="statNamePitcher">Cmd</Col>
          <Col span={3} className="statNamePitcher">s/m%</Col>
          <Col span={3} className="statNamePitcher">GB%</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">FB</Col>
          <Col span={3} className={magnetVars[0].stat}>{focus.FB?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[4].stat}>{focus.Velo?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[5].stat}>{focus['fb spin']?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[9].stat}>{focus.fbCmdF?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[13].stat}>{focus['fb sm']?.toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[17].stat}>{focus['fb gb']?.toFixed(1) || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">CB</Col>
          <Col span={3} className={magnetVars[1].stat}>{focus.CB?.toFixed(0) || '--'}</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.cbV?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[6].stat}>{focus['cb spin']?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[10].stat}>{focus.cbCmdF?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[14].stat}>{focus['cb sm']?.toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[18].stat}>{focus['cb gb']?.toFixed(1) || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">SL</Col>
          <Col span={3} className={magnetVars[2].stat}>{focus.SL?.toFixed(0) || '--'}</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.slV?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[7].stat}>{focus['sl spin']?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[11].stat}>{focus.slCmdF?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[15].stat}>{focus['sl sm']?.toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[19].stat}>{focus['sl gb']?.toFixed(1) || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">CH</Col>
          <Col span={3} className={magnetVars[3].stat}>{focus.CH?.toFixed(0) || '--'}</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.chV?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[8].stat}>{focus['ch spin']?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[12].stat}>{focus.chCmdF?.toFixed(0) || '--'}</Col>
          <Col span={3} className={magnetVars[16].stat}>{focus['ch sm']?.toFixed(1) || '--'}</Col>
          <Col span={3} className={magnetVars[20].stat}>{focus['ch gb']?.toFixed(1) || '--'}</Col>
        </Row>
        <Row>
          <Col span={3} className="statUnconditionalPitcher">OTH</Col>
          <Col span={3} className="statUnconditionalPitcher">--</Col>
          <Col span={3} className="statUnconditionalPitcher">--</Col>
          <Col span={3} className="statUnconditionalPitcher">--</Col>
          <Col span={3} className="statUnconditionalPitcher">{focus.othCmdF?.toFixed(0) || '--'}</Col>
          <Col span={3} className="statUnconditionalPitcher">--</Col>
          <Col span={3} className="statUnconditionalPitcher">--</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PlayerMagnetPitcher;
