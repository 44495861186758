import { createSlice } from '@reduxjs/toolkit';

export const roundsSlice = createSlice({
  name: 'rounds',
  initialState: {
    rounds: [{
      desc: '',
    }],
    metPicks: [],
    initRounds: [],
    roundsUnique: [],
    currentPickInRound: 0,
    currentOverall: 0,
    currentRound: 0,
    currentRoundDesc: 'Round 1',
    mode: 'board',
    pickTime: 0,
    tickerLive: true,
    draftBoardLive: true,
  },
  reducers: {
    populateRounds: (state, action) => ({
      ...state,
      rounds: action.payload,
    }),
    populateInitialRounds: (state, action) => ({
      ...state,
      initRounds: action.payload,
    }),
    populateMetPicks: (state, action) => ({
      ...state,
      metPicks: action.payload,
    }),
    changeMode: (state, action) => ({
      ...state,
      mode: action.payload,
    }),
    initializeCurrentDraftState: (state, action) => {
      if (action.payload.length !== 0) {
        let currentPickInRound = action.payload[action.payload.length - 1].pickInRound;
        const pickTime = action.payload[action.payload.length - 1].pickTime * 1000; // time: millis
        const currentOverall = action.payload[action.payload.length - 1].overallPick;
        const roundsDupe = [...action.payload];
        const roundSet = new Set([...state.roundsUnique]);
        for (let i = 0; i < roundsDupe.length; i += 1) {
          const roundFromAction = action.payload[i].round;
          roundSet.add(roundFromAction);
        }

        const currentRoundDesc = action.payload[action.payload.length - 1].round;
        let currentRound = roundSet.size;
        if (state.rounds[currentRound - 1].length > currentPickInRound + 1) {
          currentPickInRound += 1;
        } else {
          currentPickInRound = 0;
          currentRound += 1;
        }
        return {
          ...state,
          currentPickInRound,
          roundsUnique: [...roundSet],
          currentRound,
          currentRoundDesc,
          currentOverall,
          pickTime,
        };
      }
      return state;
    },
    // eslint-disable-next-line no-unused-vars
    incrementCurrentPick: (state, action) => {
      // let currentPickInRoundInc = 0;
      // let currentRoundInc = 0;
      let currentRoundDesc = '';
      const { rounds } = state;
      const { currentRound } = state;
      const { currentPickInRound } = state;
      if (rounds[currentRound - 1].length > currentPickInRound + 1) {
        // currentPickInRoundInc = 1;
        return {
          ...state,
          currentOverall: state.currentOverall + 1,
          currentPickInRound: state.currentPickInRound + 1,
        };
      }

      // currentPickInRoundInc = 0;
      // currentRoundInc = 1;
      currentRoundDesc = state.rounds[state.currentRound][0].roundCode;
      return {
        ...state,
        currentOverall: state.currentOverall + 1,
        currentPickInRound: 0,
        currentRound: state.currentRound + 1,
        currentRoundDesc,
      };
    },
    setTickerLive: (state, action) => ({
      ...state,
      tickerLive: action.payload,
    }),
    setDraftBoardLive: (state, action) => ({
      ...state,
      draftBoardLive: action.payload,
    }),
  },

});

export const selectRounds = (state) => state.rounds.rounds;
export const selectStatusPriorPicks = (state) => state.rounds.loadStatusPriorPicks;
export const selectCurrentPickInRound = (state) => state.rounds.currentPickInRound;
export const selectCurrentOverall = (state) => state.rounds.currentOverall;
export const selectCurrentRound = (state) => state.rounds.currentRound;
export const selectCurrentRoundDesc = (state) => state.rounds.currentRoundDesc;
export const selectMetPicks = (state) => state.rounds.metPicks;
export const selectMode = (state) => state.rounds.mode;
export const selectInitRounds = (state) => state.rounds.initRounds;
export const selectPickTime = (state) => state.rounds.pickTime;
export const selectTickerLive = (state) => state.rounds.tickerLive;
export const selectDraftBoardLive = (state) => state.rounds.draftBoardLive;

export const {
  populateRounds,
  incrementCurrentPick,
  populateMetPicks,
  changeMode,
  populateInitialRounds,
  initializeCurrentDraftState,
  setTickerLive,
  setDraftBoardLive,
} = roundsSlice.actions;

export default roundsSlice.reducer;
