import { createSlice } from '@reduxjs/toolkit';

export const focusSlice = createSlice({
  name: 'focus',
  initialState: {
    playerName: 'pname',
    playerAge: 22,
    currentFocus: '',
  },
  reducers: {
    changeFocus: (state, action) => ({
      ...state,
      currentFocus: action.payload,
    }),
  },
});

export const selectFocus = (state) => state.focus.currentFocus;

export const { changeFocus } = focusSlice.actions;

export default focusSlice.reducer;
