import React from 'react';
import {
  Card, Dropdown, Space, Radio, Button, Menu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import './FilterBar.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectClassFilter,
  selectHandednessFilter,
  selectPlayerFilter,
  selectPositionFilter,
  setClassFilter,
  setHandednessFilter,
  setPlayerFilter,
  setPositionFilter,
} from '../../Redux/filterSlice';
import {
  selectCurrentPickInRound, selectCurrentRound, selectDraftBoardLive, selectMode, setDraftBoardLive,
} from '../../Redux/roundsSlice';
import { scrollDraftBoardToCurrentPickForce } from '../DraftBoard/DraftBoard';

const playerFilterOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Available',
    value: 'Available',
  },
  {
    label: 'Drafted',
    value: 'Drafted',
  },
];

const handednessFilterOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'Right',
    value: 'Right',
  },
  {
    label: 'Left',
    value: 'Left',
  },
];

const classFilterOptions = [
  {
    label: 'All',
    value: 'All',
  },
  {
    label: 'College',
    value: 'College',
  },
  {
    label: 'High School',
    value: 'High School',
  },
];

// This conmponent holds all the filter buttons used to filter the player table
function FilterBar() {
  const dispatch = useDispatch();
  const mode = useSelector(selectMode);
  const currentRound = useSelector(selectCurrentRound);
  const currentPickInRound = useSelector(selectCurrentPickInRound);
  const draftBoardLive = useSelector(selectDraftBoardLive);
  const valuePlayerFilter = useSelector(selectPlayerFilter);
  const valuePositionFilter = useSelector(selectPositionFilter);
  const valueClassFilter = useSelector(selectClassFilter);
  const valueHandednessFilter = useSelector(selectHandednessFilter);
  const currentPitcherOption = 'All Pitchers';
  const currentHitterOption = 'All';

  const handleMenuClickPitcher = (e) => {
    dispatch(setPositionFilter(e.key));
  };
  const handleMenuClickHitter = (e) => {
    dispatch(setPositionFilter(e.key));
  };

  const menuPitcher = (
    <Menu
      onClick={handleMenuClickPitcher}
      selectedKeys={[currentPitcherOption]}
      items={[
        {
          label: 'All',
          key: 'All Pitchers',
        },
        {
          label: 'Starter',
          key: 'Starter',
        },
        {
          label: 'Reliever',
          key: 'Reliever',
        },
      ]}
    />
  );

  const menuHitter = (
    <Menu
      onClick={handleMenuClickHitter}
      selectedKeys={[currentHitterOption]}
      items={[
        {
          label: 'All',
          key: 'All Hitters',
        },
        {
          label: 'Premium',
          key: 'Premium',
        },
        {
          label: 'Bats',
          key: 'Bats',
        },
        {
          label: 'C',
          key: 'C',
        },
        {
          label: '1B',
          key: '1B',
        },
        {
          label: '2B',
          key: '2B',
        },
        {
          label: 'SS',
          key: 'SS',
        },
        {
          label: '3B',
          key: '3B',
        },
        {
          label: 'LF',
          key: 'LF',
        },
        {
          label: 'CF',
          key: 'CF',
        },
        {
          label: 'RF',
          key: 'RF',
        },
        {
          label: 'OF',
          key: 'OF',
        },
      ]}
    />
  );
  const updatePlayerFilter = ({ target: { value } }) => {
    dispatch(setPlayerFilter(value));
  };
  const updateHandednessFilter = ({ target: { value } }) => {
    dispatch(setHandednessFilter(value));
  };
  const updateClassFilter = ({ target: { value } }) => {
    dispatch(setClassFilter(value));
  };

  // Clicking when active should return the user to the current draft pick
  function returnToLive() {
    dispatch(setDraftBoardLive(true));
    scrollDraftBoardToCurrentPickForce(currentRound, currentPickInRound);
  }
  let buttonType = 'primary';
  let visibilityType = 'hidden';
  let pitcherButtonType = 'primary';
  let positionButtonType = 'default';
  let pitcherButtonText = 'Pitcher';
  let positionButtonText = 'Position';
  if (mode === 'board') {
    visibilityType = 'visible';
  }
  if (draftBoardLive) {
    buttonType = 'default';
  }
  if (valuePositionFilter === 'All Pitchers' || valuePositionFilter === 'Starter' || valuePositionFilter === 'Reliever') {
    pitcherButtonType = 'primary';
    positionButtonType = 'default';
    pitcherButtonText = valuePositionFilter;
    positionButtonText = 'Position';
  } else {
    pitcherButtonType = 'default';
    positionButtonType = 'primary';
    pitcherButtonText = 'Pitcher';
    positionButtonText = valuePositionFilter;
  }
  return (
    <Card className="filterBar">
      <Radio.Group
        options={playerFilterOptions}
        onChange={updatePlayerFilter}
        value={valuePlayerFilter}
        optionType="button"
        buttonStyle="solid"
      />
                &emsp;
      <Radio.Group
        options={classFilterOptions}
        onChange={updateClassFilter}
        value={valueClassFilter}
        optionType="button"
        buttonStyle="solid"
      />
                &emsp;
      <Dropdown overlay={menuPitcher}>
        <Button type={pitcherButtonType}>
          <Space>
            <span className="buttonText">{pitcherButtonText}</span>
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>

      <Dropdown overlay={menuHitter}>
        <Button type={positionButtonType}>
          <Space>
            <span className="buttonText">{positionButtonText}</span>
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
                &emsp;
      <Radio.Group options={handednessFilterOptions} onChange={updateHandednessFilter} value={valueHandednessFilter} optionType="button" buttonStyle="solid" />
                &emsp;
      <Button
        type={buttonType}
        style={{ visibility: visibilityType }}
        onClick={() => returnToLive}
      >
        Return to Live

      </Button>
    </Card>
  );
}

export default FilterBar;
