import React, { useEffect } from 'react';
import { Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRounds,
  selectCurrentRound,
  selectCurrentPickInRound,
  selectDraftBoardLive,
  setDraftBoardLive,
} from '../../Redux/roundsSlice';
import DraftRound from './DraftRound';
import './DraftBoard.css';

// Draft board component
// Shows a list of all rounds and all picks in a given draft
// Fills in each card with a player's name once a pick comes in
function DraftBoard() {
  const dispatch = useDispatch();
  const rounds = useSelector(selectRounds);
  const currentRound = useSelector(selectCurrentRound);
  const currentPickInRound = useSelector(selectCurrentPickInRound);
  const draftBoardLive = useSelector(selectDraftBoardLive);
  // const size = 30;
  const board = document.querySelector('#board');

  // If the current draft pick is off screen, do not auto scroll
  function checkOffScreen() {
    const scrollDiv = document.querySelector(`#board_round_${(currentRound - 1).toString()}_pick_${currentPickInRound.toString()}`);
    if (scrollDiv?.offsetTop < ((board.scrollTop || 0) - (scrollDiv?.clientHeight || 0))
        || scrollDiv?.offsetTop > ((board?.scrollTop || 0)
            + (board?.clientHeight || 0) + (scrollDiv?.clientHeight || 0))) {
      dispatch(setDraftBoardLive(false));
    } else if (!draftBoardLive) {
      dispatch(setDraftBoardLive(true));
    }
  }

  // Scroll to current pick
  function scrollDraftBoardToCurrentPick() {
    if (currentRound > 0 && draftBoardLive) {
      const scrollDiv = document.querySelector(`#board_round_${(currentRound - 1).toString()}_pick_${currentPickInRound.toString()}`);
      document.querySelector('#board').scrollTo({ top: (scrollDiv?.offsetTop || 0) - (230), behavior: 'smooth' });
    }
  }

  useEffect(() => {
    scrollDraftBoardToCurrentPick();
    board?.addEventListener('scroll', checkOffScreen);
    return () => {
      board?.removeEventListener('scroll', checkOffScreen);
    };
  }, [currentPickInRound]);

  return (
    rounds.map((r, i) => (
      <>
        <div className={`board_round_${i}`} id={`board_round_${i}`}>
          <span className="roundNameDraftBoard">{r[i]?.desc}</span>
        </div>
        <Row><DraftRound round={i} /></Row>
      </>
    ))
  );
}

export default DraftBoard;
// Export function to scroll to draft pick
export function scrollDraftBoardToCurrentPickForce(currentRound, currentPickInRound) {
  if (currentRound > 0) {
    const scrollDiv = document.querySelector(`#board_round_${(currentRound - 1).toString()}_pick_${currentPickInRound.toString()}`);
    document.querySelector('#board').scrollTo({ top: (scrollDiv?.offsetTop || 0) - (230), behavior: 'smooth' });
  }
}
