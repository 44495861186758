import { createSlice } from '@reduxjs/toolkit';

export const playersSlice = createSlice({
  name: 'players',
  initialState: {
    players: 'init',
    filteredPlayers: [],
    drafted: [],
    previousTime: 0,
    previousDraftedLength: 0,
    loadStatus: 'UNLOADED',
    hasPickOccured: false,

  },
  reducers: {
    populatePlayers: (state, action) => ({
      ...state,
      players: action.payload,
      filteredPlayers: action.payload,
    }),
    populateDraftedPlayers: (state, action) => {
      if (action.payload.length > 0) {
        return {
          ...state,
          drafted: action.payload,
        };
      }
      return state;
    },
    initializeDraftedPlayers: (state, action) => {
      if (action.payload.length > 0) {
        return {
          ...state,
          drafted: action.payload,
          loadStatus: 'LOADED',
        };
      }
      return state;
    },
    hasDraftAdvanced: (state, action) => {
      if (action.payload === '') {
        return {
          ...state,
          hasPickOccured: false,
        };
      }

      return {
        ...state,
        hasPickOccured: true,
      };
    },
    filterPlayers: (state, action) => {
      let filterInit = state.players;
      if (action.payload.length >= 3) {
        filterInit = state.players.filter((p) => p.playerName.toLowerCase().includes(action.payload)
        || p.schoolName.toLowerCase().includes(action.payload));
        return {
          ...state,
          filteredPlayers: filterInit,
        };
      }

      return {
        ...state,
        filteredPlayers: filterInit,
      };
    },
    pushDraftedPlayer: (state, action) => {
      state.drafted.push(action.payload);
      const playerArray = [...state.players];
      playerArray.find((o, i) => {
        if (o.draftId.toString() === action.payload.key.toString()) {
          // eslint-disable-next-line no-param-reassign
          state.players[i].drafted = 1;
          return true;
        }
        return false;
      });
    },
    changeStatus: (state, action) => {
      const playerArray = [...state.players];

      playerArray.find((o, i) => {
        if (o.draftId.toString() === action.payload.recordKey.toString()) {
          // eslint-disable-next-line no-param-reassign
          state.players[i].Status = action.payload.e;
          return true;
        }
        return false;
      });
    },
  },
  setPreviousTime: (state, action) => ({
    ...state,
    previousTime: action.payload,
  }),

});

export const selectPlayers = (state) => state.players.players;
export const selectFilterPlayers = (state) => state.players.filteredPlayers;
export const selectDrafted = (state) => state.players.drafted;
export const selectStatus = (state) => state.players.loadStatus;
export const selectPreviousTime = (state) => state.players.previousTime;
export const selectPreviousDraftedLength = (state) => state.players.previousDraftedLength;
export const selectHasPickOccured = (state) => state.players.hasPickOccured;

export const {
  populatePlayers,
  populateDraftedPlayers,
  pushDraftedPlayer,
  changeStatus,
  initializeDraftedPlayers,
  filterPlayers,
  setPreviousTime,
  hasDraftAdvanced,
} = playersSlice.actions;

export default playersSlice.reducer;
