import { createSlice } from '@reduxjs/toolkit';

export const filterSlice = createSlice({
  name: 'filter',
  initialState: {
    playerFilter: 'Available',
    positionFilter: 'All Pitchers',
    handednessFilter: 'All',
    classFilter: 'All',
  },
  reducers: {
    setPlayerFilter: (state, action) => {
      if (action.payload !== undefined) {
        // console.log('setPlayerFilter', action.payload);
        return {
          ...state,
          playerFilter: action.payload,
        };
      }
      return state;
    },
    setPositionFilter: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          positionFilter: action.payload,
        };
      }
      return state;
    },
    setHandednessFilter: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          handednessFilter: action.payload,
        };
      }
      return state;
    },
    setClassFilter: (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          classFilter: action.payload,
        };
      }
      return state;
    },
  },

});

export const selectPlayerFilter = (state) => state.filter.playerFilter;
export const selectPositionFilter = (state) => state.filter.positionFilter;
export const selectHandednessFilter = (state) => state.filter.handednessFilter;
export const selectClassFilter = (state) => state.filter.classFilter;

export const {
  setPlayerFilter, setPositionFilter, setHandednessFilter, setClassFilter,
} = filterSlice.actions;

export default filterSlice.reducer;
