import { Button, Select } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TickerCard from './TickerCard';
import {
  selectCurrentPickInRound,
  selectCurrentRound,
  selectRounds,
  selectTickerLive,
  setTickerLive,
  selectCurrentOverall,
} from '../../Redux/roundsSlice';
import { selectDrafted } from '../../Redux/playersSlice';
import teams from '../../helpers/teamMap';
import './Ticker.css';

// Top-level component for the ticker
// Shows the current state of the draft
// Includes a dropdown for easier navigation
function Ticker() {
  const dispatch = useDispatch();
  const rounds = useSelector(selectRounds);
  const currentRound = useSelector(selectCurrentRound);
  const currentPickInRound = useSelector(selectCurrentPickInRound);
  const tickerLive = useSelector(selectTickerLive);
  const drafted = useSelector(selectDrafted);
  const myRef = useRef(null);
  const ticker = document.querySelector('#ticker');
  const currentOverall = useSelector(selectCurrentOverall);
  let buttonType = 'default';
  if (tickerLive) {
    buttonType = 'default';
  } else {
    buttonType = 'primary';
  }
  function checkOffScreen() {
    const scrollDiv = document.querySelector(`#ticker_round_${(currentRound - 1).toString()}_pick_${(currentPickInRound).toString()}`);
    if (scrollDiv?.offsetLeft < ((ticker?.scrollLeft || 0) - (scrollDiv?.clientWidth || 0))
    || (scrollDiv?.offsetLeft || 0) > ((ticker?.scrollLeft || 0)
        + (ticker?.clientWidth || 0) + (scrollDiv?.clientWidth || 0))) {
      dispatch(setTickerLive(false));
      buttonType = 'primary';
    } else if (!tickerLive) {
      dispatch(setTickerLive(true));
      buttonType = 'default';
    }
  }
  // Auto scroll to the current pick
  function autoscrollTickerToCurrentPick() {
    if (currentRound > 0 && tickerLive) {
      const scrollDiv = document.querySelector(`#overall_${(currentOverall + 1).toString()}`);
      document.querySelector('#ticker').scrollTo({
        left: (scrollDiv?.offsetLeft || 0) - (window.innerWidth / 2)
      + ((scrollDiv?.clientWidth || 0) / 2),
        behavior: 'smooth',
      });
    }
  }
  // Manual scroll to current pick
  function scrollTickerToCurrentPick() {
    if (currentRound > 0) {
      // console.log('query', `#overall_${(currentOverall + 1).toString()}`);
      const scrollDiv = document.querySelector(`#overall_${(currentOverall + 1).toString()}`);
      // console.log(scrollDiv);
      document.querySelector('#ticker')
        .scrollTo({ left: (scrollDiv?.offsetLeft || 0) - (window.innerWidth / 2) + ((scrollDiv?.clientWidth || 0) / 2), behavior: 'smooth' });
    }
  }
  // Scroll to the beginning of a given round
  function scrollTickerToRound(val, obj) {
    if (currentRound > 0) {
      const scrollDiv = document.querySelector(`#ticker_round_${(obj.key).toString()}`);
      document.querySelector('#ticker')
        .scrollTo({ left: (scrollDiv?.offsetLeft || 0) - ((scrollDiv?.clientWidth || 0) * 2) - 190, behavior: 'smooth' });
    }
  }
  useEffect(() => {
    autoscrollTickerToCurrentPick();
    ticker?.addEventListener('scroll', checkOffScreen);
    return () => {
      ticker?.removeEventListener('scroll', checkOffScreen);
    };
  }, [currentPickInRound]);
  // Check is the current pick is offscreen

  const board = [];
  const roundNames = [];
  let pick;
  let current;
  let overall = 0;
  if (rounds.length > 1) {
    for (let j = 0; j < rounds.length; j += 1) {
      board.push(
        <div ref={myRef} className="ticker_round" id={`ticker_round_${j}`}>
          <span className="tickerRoundText">{rounds[j][0].desc.toUpperCase()}</span>
        </div>,
      );
      roundNames.push({ value: rounds[j][0].desc, key: j });
      for (let i = 0; i < rounds[j].length; i += 1) {
        overall += 1;
        if (rounds[j][i].overallPickNumber - 1 <= drafted?.length) {
          pick = drafted[rounds[j][i].overallPickNumber - 1];
        } else {
          pick = null;
        }
        if (rounds[j][i].overallPickNumber - 1 === drafted?.length) {
          current = true;
        } else {
          current = null;
        }
        board.push(
          <div style={{ border: !!current && '3px solid red' }} className={`overall_${overall}`} id={`overall_${overall}`}>
            <TickerCard
              pick={rounds[j][i].pickInRound}
              teamName={teams[rounds[j][i].orgId]}
              totalPick={rounds[j][i].overallPickNumber}
              desc={rounds[j][i].roundCode}
              roundName={rounds[j][i].desc.split(' ')[rounds[j][i].desc.split(' ').length - 1]}
              player={pick}
              current={current}
            />

          </div>,
        );
      }
    }
  }

  return (
    <div className="tickerContainer">
      <Select
        options={roundNames}
        defaultValue={['Round 1']}
        onSelect={() => scrollTickerToRound}
        className="tickerSelect"
      />
      <Button onClick={() => scrollTickerToCurrentPick} type={buttonType}>
        Live
      </Button>
      <div className="ticker" id="ticker">
        {board.map((i) => i)}
      </div>
    </div>
  );
}

export default Ticker;
