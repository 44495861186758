import React from 'react';
import { Card } from 'antd';
import DraftBoard from './DraftBoard';
import './DraftBoardContainerCard.css';

// Top-level component for the draft board
function DraftBoardContainerCard() {
  return (
    <Card className="draftBoardContainerCard" id="board">
      <DraftBoard />
    </Card>
  );
}

export default DraftBoardContainerCard;
