import React from 'react';
import { Input, Table } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { changeFocus } from '../../Redux/focusSlice';
import { selectDrafted, selectFilterPlayers, filterPlayers } from '../../Redux/playersSlice';
import {
  selectClassFilter, selectHandednessFilter, selectPlayerFilter, selectPositionFilter,
} from '../../Redux/filterSlice';
import './PlayerTable.css';

const { Search } = Input;

// Table used to display draftable players
function PlayerTable() {
  const dispatch = useDispatch();
  const players = useSelector(selectFilterPlayers);
  const drafted = useSelector(selectDrafted);
  const scrollLength = 345;
  const draftedFilter = useSelector(selectPlayerFilter);
  const positionFilter = useSelector(selectPositionFilter);
  const handednessFilter = useSelector(selectHandednessFilter);
  const classFilter = useSelector(selectClassFilter);
  let columns = [
    {
      title: 'Rank',
      dataIndex: 'Rank',
      key: 'Rank',
      width: 40,
      sorter: (a, b) => a.Rank - b.Rank,
    },
    {
      title: 'Pos rank',
      dataIndex: 'PosRank',
      key: 'PosRank',
      width: 40,
      sorter: (a, b) => a.PosRank - b.PosRank,
    },
    {
      title: 'Pos',
      dataIndex: 'pos',
      key: 'pos',
      width: 70,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => <a href={`https://portal2.nymets.com/player/index.php?id=${record.portalId}`} target="_blank" rel="noreferrer">{text}</a>,
      width: 190,
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'School',
      dataIndex: 'School',
      key: 'School',
      width: 135,
      sorter: (a, b) => {
        if (a.School < b.School) {
          return -1;
        }
        if (a.School > b.School) {
          return 1;
        }
        return 0;
      },
    },
    {
      title: 'B/T',
      dataIndex: 'BT',
      key: 'BT',
      width: 40,
    },
    {
      title: 'S&S',
      dataIndex: 'SandS',
      key: 'SandS',
      width: 40,
      sorter: (a, b) => a.SandS.toString().localeCompare(b.SandS.toString(), navigator.languages[0]
      || navigator.language, { numeric: true, ignorePunctuation: true }),
    },
    {
      title: 'STATS',
      dataIndex: 'Stats',
      key: 'Stats',
      width: 40,
      sorter: (a, b) => a.Stats.toString().localeCompare(b.Stats.toString(), navigator.languages[0]
      || navigator.language, { numeric: true, ignorePunctuation: true }),
    },
    {
      title: 'Media',
      dataIndex: 'Media',
      key: 'Media',
      width: 40,
      sorter: (a, b) => a.Media.toString().localeCompare(b.Media.toString(), navigator.languages[0]
      || navigator.language, { numeric: true, ignorePunctuation: true }),
    },
    {
      title: 'Role',
      dataIndex: 'Role',
      key: 'Role',
      width: 40,
      sorter: (a, b) => a.Role.toString() - b.Role.toString(),
    },
    {
      title: 'Prob',
      dataIndex: 'Prob',
      key: 'Prob',
      width: 40,
      sorter: (a, b) => a.Prob.toString() - b.Prob.toString(),
    },
    {
      title: '# of Evals',
      dataIndex: 'evalNum',
      key: 'evalNum',
      width: 40,
      sorter: (a, b) => a.evalNum.toString().localeCompare(
        b.evalNum.toString(),
        navigator.languages[0]
      || navigator.language,

        { numeric: true, ignorePunctuation: true },
      ),
    },
  ];
  if (positionFilter === 'All Pitchers' || positionFilter === 'Starter' || positionFilter === 'Reliever') {
    const pitcherColumns = [
      {
        title: 'IP',
        dataIndex: 'IP',
        key: 'IP',
        width: 40,
        sorter: (a, b) => a.IP.toString() - b.IP.toString(),
      },
      {
        title: 'FB Velo',
        dataIndex: 'FBVelo',
        key: 'FBVelo',
        width: 40,
        sorter: (a, b) => a.FBVelo.toString() - b.FBVelo.toString(),
      },
      {
        title: 'CB/SL',
        dataIndex: 'CBSL',
        key: 'CBSL',
        width: 40,
        sorter: (a, b) => a.CBSL.toString() - b.CBSL.toString(),
      },
      {
        title: 'CH',
        dataIndex: 'CH',
        key: 'CH',
        width: 40,
        sorter: (a, b) => a.CH.toString() - b.CH.toString(),
      },
      {
        title: 'Adj K%',
        dataIndex: 'AdjK',
        key: 'AdjK',
        width: 40,
        sorter: (a, b) => a.AdjK.toString() - b.AdjK.toString(),
      },
      {
        title: 'Adj BB%',
        dataIndex: 'AdjBB',
        key: 'AdjBB',
        width: 40,
        sorter: (a, b) => a.AdjBB.toString() - b.AdjBB.toString(),
      },
    ];
    columns = [...columns, ...pitcherColumns];
  } else if (positionFilter !== 'All Pitchers' && positionFilter !== 'Starter' && positionFilter !== 'Reliever') {
    const hitterColumns = [
      {
        title: 'Hit',
        dataIndex: 'Hit',
        key: 'Hit',
        width: 40,
        sorter: (a, b) => a.Hit.toString() - b.Hit.toString(),
      },
      {
        title: 'Pow',
        dataIndex: 'Pow',
        key: 'Pow',
        width: 40,
        sorter: (a, b) => a.Pow.toString() - b.Pow.toString(),
      },
      {
        title: 'Def',
        dataIndex: 'Def',
        key: 'Def',
        width: 40,
        sorter: (a, b) => a.Def.toString() - b.Def.toString(),
      },
      {
        title: 'Run',
        dataIndex: 'Run',
        key: 'Run',
        width: 40,
        sorter: (a, b) => a.Run.toString() - b.Run.toString(),
      },
      {
        title: 'Arm',
        dataIndex: 'Arm',
        key: 'Arm',
        width: 40,
        sorter: (a, b) => a.Arm.toString() - b.Arm.toString(),
      },
      {
        title: 'Adj K%',
        dataIndex: 'AdjK',
        key: 'AdjK',
        width: 40,
        sorter: (a, b) => a.AdjK.toString() - b.AdjK.toString(),
      },
      {
        title: 'Adj BB%',
        dataIndex: 'AdjBB',
        key: 'AdjBB',
        width: 40,
        sorter: (a, b) => a.AdjBB.toString() - b.AdjBB.toString(),
      },
      {
        title: 'Adj RVC',
        dataIndex: 'AdjRVC',
        key: 'AdjRVC',
        width: 40,
        sorter: (a, b) => a.AdjRVC.toString() - b.AdjRVC.toString(),
      },
    ];
    columns = [...columns, ...hitterColumns];
  }

  let playersFilteredv1 = [];
  let playersFilteredv2 = [];
  let playersFilteredv3 = [];
  let playersFilteredv4 = [];
  let playersFiltered = [];

  const onSearch = (value) => {
    playersFiltered = players.filter((p) => p.playerName.includes(value));
  };
  // Filter the tables based on what's in the search bar (min 3 chars)
  const onChange = (value) => {
    dispatch(filterPlayers(value.target.value?.toLowerCase()));
  };
  const data = [];
  // Filters
  if (players !== 'init') {
    // Filter by draft status
    if (draftedFilter === 'All') {
      playersFilteredv1 = players;
    } else if (draftedFilter === 'Available') {
      let res = [];
      res = players.filter((el) => !drafted.find((element) => element.draftId === el.draftId));
      playersFilteredv1 = res;
    } else if (draftedFilter === 'Drafted') {
      let res = [];
      res = players.filter((el) => drafted.find((element) => element.draftId === el.draftId));
      playersFilteredv1 = res;
    }

    // Filter by position
    if (positionFilter === 'All') {
      playersFilteredv2 = playersFilteredv1;
    } else if (positionFilter === 'All Pitchers') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'SP' || player.Pos === 'RP'
      || player.Pos === 'RHS' || player.Pos === 'LHS' || player.Pos === 'RHR' || player.Pos === 'LHR'
       || player.position === 'SP' || player.position === 'RP' || player.position === 'RHS' || player.position === 'LHS'
       || player.position === 'RHR' || player.position === 'LHR');
      playersFilteredv2 = res;
    } else if (positionFilter === 'Starter') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'SP' || player.Pos === 'RHS' || player.Pos === 'LHS'
      || player.position === 'SP' || player.position === 'RHS' || player.position === 'LHS');
      playersFilteredv2 = res;
    } else if (positionFilter === 'Reliever') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'RP' || player.Pos === 'RHR' || player.Pos === 'LHR'
      || player.position === 'RP' || player.position === 'RHR' || player.position === 'LHR');
      playersFilteredv2 = res;
    } else if (positionFilter === 'All Hitters') {
      let res = [];
      res = playersFilteredv1.filter((player) => (player.Pos === '1B' || player.Pos === '2B' || player.Pos === '3B'
       || player.Pos === 'LF' || player.Pos === 'RF' || player.position === '1B' || player.position === '2B'
       || player.position === '3B' || player.position === 'LF' || player.position === 'RF' || player.Pos === 'C'
       || player.Pos === 'CF' || player.Pos === 'SS' || player.position === 'C' || player.position === 'CF' || player.position === 'SS'));
      playersFilteredv2 = res;
    } else if (positionFilter === 'Premium') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'C' || player.Pos === 'CF' || player.Pos === 'SS'
       || player.position === 'C' || player.position === 'CF' || player.position === 'SS');
      playersFilteredv2 = res;
    } else if (positionFilter === 'Bats') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === '1B' || player.Pos === '2B' || player.Pos === '3B'
       || player.Pos === 'LF' || player.Pos === 'RF' || player.position === '1B' || player.position === '2B'
       || player.position === '3B' || player.position === 'LF' || player.position === 'RF');
      playersFilteredv2 = res;
    } else if (positionFilter === 'C') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'C' || player.position === 'C');
      playersFilteredv2 = res;
    } else if (positionFilter === '1B') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === '1B' || player.position === '1B');
      playersFilteredv2 = res;
    } else if (positionFilter === '2B') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === '2B' || player.position === '2B');
      playersFilteredv2 = res;
    } else if (positionFilter === '3B') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === '3B' || player.position === '3B');
      playersFilteredv2 = res;
    } else if (positionFilter === 'SS') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'SS' || player.position === 'SS');
      playersFilteredv2 = res;
    } else if (positionFilter === 'LF') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'LF' || player.position === 'LF');
      playersFilteredv2 = res;
    } else if (positionFilter === 'CF') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'CF' || player.position === 'CF');
      playersFilteredv2 = res;
    } else if (positionFilter === 'RF') {
      let res = [];
      res = playersFilteredv1.filter((player) => player.Pos === 'RF' || player.position === 'RF');
      playersFilteredv2 = res;
    } else if (positionFilter === 'OF') {
      let res = [];
      res = playersFilteredv1.filter((player) => (player.Pos === 'LF' || player.Pos === 'CF' || player.Pos === 'RF'
      || player.position === 'LF' || player.position === 'CF' || player.position === 'RF')); // all OF
      playersFilteredv2 = res;
    }

    // Filter by handedness
    if (handednessFilter === 'All') {
      playersFilteredv3 = playersFilteredv2;
    } else if (handednessFilter === 'Right' && (positionFilter === 'All Pitchers' || positionFilter === 'Starter' || positionFilter === 'Reliever')) {
      let res = [];
      res = playersFilteredv2.filter((player) => (player.throwingSide === 'Right' || player.throwingSide === 'Switch'));
      playersFilteredv3 = res;
    } else if (handednessFilter === 'Right' && (positionFilter !== 'All Pitchers' && positionFilter !== 'Starter' && positionFilter !== 'Reliever')) {
      let res = [];
      res = playersFilteredv2.filter((player) => (player.battingSide === 'Right' || player.battingSide === 'Switch'));
      playersFilteredv3 = res;
    } else if (handednessFilter === 'Left' && (positionFilter === 'All Pitchers' || positionFilter === 'Starter' || positionFilter === 'Reliever')) {
      let res = [];
      res = playersFilteredv2.filter((player) => (player.throwingSide === 'Left' || player.throwingSide === 'Switch'));
      playersFilteredv3 = res;
    } else if (handednessFilter === 'Left' && (positionFilter !== 'All Pitchers' && positionFilter !== 'Starter' && positionFilter !== 'Reliever')) {
      let res = [];
      res = playersFilteredv2.filter((player) => (player.battingSide === 'Left' || player.battingSide === 'Switch'));
      playersFilteredv3 = res;
    }

    // Filter by class
    if (classFilter === 'All') {
      playersFilteredv4 = playersFilteredv3;
    } else if (classFilter === 'College') {
      let res = [];
      res = playersFilteredv3.filter((player) => !player.schoolClass?.includes('HS'));
      playersFilteredv4 = res;
    } else if (classFilter === 'High School') {
      let res = [];
      res = playersFilteredv3.filter((player) => player.schoolClass?.includes('HS'));
      playersFilteredv4 = res;
    }
    playersFiltered = playersFilteredv4;

    for (let i = 0; i < playersFiltered.length; i += 1) {
      let breaking = '';
      const IPOuts = playersFiltered[i].IP?.toFixed(1).toString();
      let outs = 0;
      let ipFinal = '--';
      if (playersFiltered[i].CB > playersFiltered[i].SL) {
        breaking = playersFiltered[i].CB;
      } else {
        breaking = playersFiltered[i].SL;
      }
      if (playersFiltered[i].IP !== null) {
        // Lazy conversion to ternary
        if (IPOuts[IPOuts.length - 1] === '3') {
          outs = 1;
        } else if (IPOuts[IPOuts.length - 1] === '6' || IPOuts[IPOuts.length - 1] === '7') {
          outs = 2;
        }
        ipFinal = parseFloat(`${Math.trunc(playersFiltered[i].IP)}.${outs}`);
      }

      // console.log(players[i]);
      data.push({
        key: playersFiltered[i].draftId,
        portalId: playersFiltered[i].portalId || '--',
        Rank: playersFiltered[i]['Val Rank'] || '--',
        PosRank: playersFiltered[i].Pos_Rank || '--', // Position rank
        name: playersFiltered[i].rosterFirstPlayerName,
        pos: playersFiltered[i].Pos || playersFiltered[i].position,
        BT: (`${playersFiltered[i].Bats || playersFiltered[i].battingSide[0]}/${playersFiltered[i].Thr || playersFiltered[i].throwingSide[0]}`),
        School: playersFiltered[i].schoolName,
        SandS: playersFiltered[i].Value?.toFixed(1) || '--',
        Stats: playersFiltered[i].Perf?.toFixed(1) || '--',
        Media: playersFiltered[i].Public || '--',
        Role: playersFiltered[i].Role?.toFixed(0) || '--',
        Prob: playersFiltered[i].Prob?.toFixed(0) || '--',
        evalNum: playersFiltered[i].Reps || '--',

        IP: ipFinal,
        FBVelo: playersFiltered[i].Velo?.toFixed(0) || '--',
        CBSL: breaking?.toFixed(0) || '--', // Highest value of CB OR SL
        CH: playersFiltered[i].CH?.toFixed(0) || '--',
        AdjK: (playersFiltered[i].Adj * 100)?.toFixed(1) || '--',
        AdjBB: (playersFiltered[i]['Adj.1'] * 100)?.toFixed(1) || '--',

        Hit: playersFiltered[i].HA?.toFixed(0) || '--',
        Pow: playersFiltered[i].RP?.toFixed(0) || '--',
        Def: playersFiltered[i].FA?.toFixed(0) || '--',
        Run: playersFiltered[i].RS?.toFixed(0) || '--',
        Arm: playersFiltered[i].AS?.toFixed(0) || '--',
        AdjRVC: (playersFiltered[i]['Adj.2'] * 100)?.toFixed(1) || '--',
      });
    }
  }

  return (
    <>
      <Search onSearch={onSearch} onChange={onChange} className="searchBar" />
      <Table
        columns={columns}
        dataSource={data}
        pagination={{ defaultPageSize: 100, pageSizeOptions: [100, 200, 500, 1000] }}
        scroll={{ y: scrollLength }}
        className="playerTable"
        onRow={(record) => ({

          onClick: () => {
            players.find((o) => {
              if (o.draftId?.toString() === record.key.toString()) {
                dispatch(changeFocus(o));
                return true;
              }
              return false;
            });
          },
        })}
      />

    </>

  );
}

export default PlayerTable;
