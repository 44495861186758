import React from 'react';
import PropTypes from 'prop-types';
import { Space, Card } from 'antd';
import { useSelector } from 'react-redux';
import { selectDrafted } from '../../Redux/playersSlice';
import {
  selectRounds,
  selectCurrentOverall,
} from '../../Redux/roundsSlice';
import ARI from '../../images/ARI.svg';
import ATL from '../../images/ATL.svg';
import BAL from '../../images/BAL.svg';
import BOS from '../../images/BOS.svg';
import CHC from '../../images/CHC.svg';
import CIN from '../../images/CIN.svg';
import CLE from '../../images/CLE.svg';
import COL from '../../images/COL.svg';
import CWS from '../../images/CWS.svg';
import DET from '../../images/DET.svg';
import HOU from '../../images/HOU.svg';
import KC from '../../images/KC.svg';
import LAD from '../../images/LAD.svg';
import LAA from '../../images/LAA.svg';
import MIA from '../../images/MIA.svg';
import MIL from '../../images/MIL.svg';
import MIN from '../../images/MIN.svg';
import NYM from '../../images/NYM.svg';
import NYY from '../../images/NYY.svg';
import OAK from '../../images/OAK.svg';
import PHI from '../../images/PHI.svg';
import PIT from '../../images/PIT.svg';
import SDP from '../../images/SDP.svg';
import SEA from '../../images/SEA.svg';
import SFG from '../../images/SFG.svg';
import STL from '../../images/STL.svg';
import TBR from '../../images/TBR.svg';
import TEX from '../../images/TEX.svg';
import TOR from '../../images/TOR.svg';
import WSH from '../../images/WSH.svg';
import './DraftBoardCard.css';

const logos = {
  ARI,
  ATL,
  BAL,
  BOS,
  CHC,
  CIN,
  CLE,
  COL,
  CWS,
  DET,
  HOU,
  KC,
  LAD,
  LAA,
  MIA,
  MIL,
  MIN,
  NYM,
  NYY,
  OAK,
  PHI,
  PIT,
  SDP,
  SEA,
  SFG,
  STL,
  TBR,
  TEX,
  TOR,
  WSH,
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

// An individual draft pick
// Displays pick name, school, round, overall #, drafting team,
// drafting team icon, position, class type and signing bonus
function DraftBoardCard({
  round,
  pick,
  totalPick,
  roundName,
  teamName,
}) {
  const currentOverall = useSelector(selectCurrentOverall);
  const rounds = useSelector(selectRounds);
  const drafted = useSelector(selectDrafted);
  // const mode = useSelector(selectMode);

  let posFinal = '--';
  let nameFinal = '--';
  let schoolFinal = '--';
  let classTypeFinal = '--';
  if (totalPick <= drafted.length) {
    posFinal = drafted[totalPick - 1].positionCode;
    nameFinal = drafted[totalPick - 1]['playerInfo.playerName'];
    schoolFinal = drafted[totalPick - 1]['playerInfo.schoolName'];
    classTypeFinal = drafted[totalPick - 1]['playerInfo.schoolClass'];
  }

  let styleConditional = 'draftBoardSubCardEmpty';
  let superCardStyle = 'draftBoardCard';
  let nameFontSize = 26;
  let fontSize = 20;

  if (nameFinal.length > 17) {
    nameFontSize = 20;
  }
  if (schoolFinal.length > 17) {
    fontSize = 20;
  }
  if (totalPick === currentOverall + 1) {
    superCardStyle = 'draftBoardCardBordered';
  }
  if (classTypeFinal !== null) {
    if (classTypeFinal.includes('4YR') && classTypeFinal.includes('SR')) {
      styleConditional = 'draftBoardSubCardSR';
    } else if (classTypeFinal.includes('4YR')) {
      styleConditional = 'draftBoardSubCardCol';
    } else if (classTypeFinal.includes('HS')) {
      styleConditional = 'draftBoardSubCardHS';
    } else if (classTypeFinal.includes('JC')) {
      styleConditional = 'draftBoardSubCardJC';
    }
  }

  const logo = logos[teamName];

  return (
    <Card className={superCardStyle}>
      <Space direction="horizontal">
        <Space direction="vertical">
          <div className="roundTextDraftBoardCard">
            {' '}
            {roundName}
            -
            {totalPick}
            {' '}
          </div>
          <img className="draftboardLogo" src={logo} alt="draftboardLogo" />
        </Space>
        <Card className={styleConditional}>
          <div style={{
            fontSize: nameFontSize, display: 'inline', verticalAlign: 'super', fontWeight: 600,
          }}
          >
            {nameFinal}
          </div>
          <div style={{ fontSize, fontWeight: 600 }}>{schoolFinal}</div>
          <div className="bottomDivDraftBoardCard">
            <p className="signingBonusTextDraftBoardCard">{formatter.format(rounds[round][pick - 1]?.signingBonusValue)}</p>
            <p className="positionTextDraftBoardCard">{posFinal}</p>
          </div>

        </Card>
      </Space>
    </Card>
  );
}

DraftBoardCard.propTypes = {
  round: PropTypes.number.isRequired,
  pick: PropTypes.number.isRequired,
  totalPick: PropTypes.number.isRequired,
  roundName: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
};

export default DraftBoardCard;
