import { configureStore } from '@reduxjs/toolkit';
import focusReducer from '../Redux/focusSlice';
import playersSliceReducer from '../Redux/playersSlice';
import roundsSliceReducer from '../Redux/roundsSlice';
import ebisSliceReducer from '../Redux/ebisSlice';
import filterSliceReducer from '../Redux/filterSlice';

const store = configureStore({
  reducer: {
    focus: focusReducer,
    players: playersSliceReducer,
    rounds: roundsSliceReducer,
    ebis: ebisSliceReducer,
    filter: filterSliceReducer,
  },
});

export default store;
