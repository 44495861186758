import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectCurrentPickInRound,
  selectCurrentRoundDesc,
} from '../../Redux/roundsSlice';
import './TickerCard.css';
import ARI from '../../images/ARI.svg';
import ATL from '../../images/ATL.svg';
import BAL from '../../images/BAL.svg';
import BOS from '../../images/BOS.svg';
import CHC from '../../images/CHC.svg';
import CIN from '../../images/CIN.svg';
import CLE from '../../images/CLE.svg';
import COL from '../../images/COL.svg';
import CWS from '../../images/CWS.svg';
import DET from '../../images/DET.svg';
import HOU from '../../images/HOU.svg';
import KC from '../../images/KC.svg';
import LAD from '../../images/LAD.svg';
import LAA from '../../images/LAA.svg';
import MIA from '../../images/MIA.svg';
import MIL from '../../images/MIL.svg';
import MIN from '../../images/MIN.svg';
import NYM from '../../images/NYM.svg';
import NYY from '../../images/NYY.svg';
import OAK from '../../images/OAK.svg';
import PHI from '../../images/PHI.svg';
import PIT from '../../images/PIT.svg';
import SDP from '../../images/SDP.svg';
import SEA from '../../images/SEA.svg';
import SFG from '../../images/SFG.svg';
import STL from '../../images/STL.svg';
import TBR from '../../images/TBR.svg';
import TEX from '../../images/TEX.svg';
import TOR from '../../images/TOR.svg';
import WSH from '../../images/WSH.svg';

const logos = {
  ARI,
  ATL,
  BAL,
  BOS,
  CHC,
  CIN,
  CLE,
  COL,
  CWS,
  DET,
  HOU,
  KC,
  LAD,
  LAA,
  MIA,
  MIL,
  MIN,
  NYM,
  NYY,
  OAK,
  PHI,
  PIT,
  SDP,
  SEA,
  SFG,
  STL,
  TBR,
  TEX,
  TOR,
  WSH,
};

// A card containing info for each draft pick
function TickerCard({
  current,
  pick,
  desc,
  roundName,
  teamName,
  player,
}) {
  const currentPick = useSelector(selectCurrentPickInRound);
  const currentRoundDesc = useSelector(selectCurrentRoundDesc);
  let styleConditional = 'tickerCard';
  const logo = logos[teamName];

  if (current) {
    styleConditional = 'tickerCardBordered';
  } else {
    styleConditional = 'tickerCard';
  }
  if (teamName === 'NYM') {
    styleConditional = 'tickerCardMets';
    if (pick === currentPick && desc === currentRoundDesc) {
      styleConditional = 'tickerCardMetsBordered';
    }
  }
  let styleDrafted = {};
  if (!player) {
    styleDrafted = { backgroundColor: '#E6F7FF' };
  }

  return (
    <Card
      bodyStyle={{
        display: 'flex', alignItems: 'center', fontSize: '16px', ...styleDrafted,
      }}
      className={styleConditional}
    >
      <span className="tickerPick">
        {roundName}
        /
        {pick}
        <img className="tickerLogo" src={logo} alt="tickerLogo" />
      </span>
      {!!player && (
        <span>
          {player['playerInfo.rosterFirstPlayerName']}
          ,
          {' '}
          {player.positionCode}
        </span>
      )}
    </Card>
  );
}

TickerCard.propTypes = {
  current: PropTypes.number.isRequired,
  pick: PropTypes.number.isRequired,
  desc: PropTypes.string.isRequired,
  roundName: PropTypes.string.isRequired,
  teamName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  player: PropTypes.object.isRequired,
};

export default TickerCard;
