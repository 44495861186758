import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { useSelector } from 'react-redux';
import {
  selectRounds,
  selectCurrentPickInRound,
  selectCurrentRound,
  selectMetPicks,
  selectCurrentOverall,
  selectInitRounds,
} from '../../Redux/roundsSlice';
import teams from '../../helpers/teamMap';
import './timerCard.css';
import { selectDrafted, selectHasPickOccured } from '../../Redux/playersSlice';
import ARI from '../../images/ARI.svg';
import ATL from '../../images/ATL.svg';
import BAL from '../../images/BAL.svg';
import BOS from '../../images/BOS.svg';
import CHC from '../../images/CHC.svg';
import CIN from '../../images/CIN.svg';
import CLE from '../../images/CLE.svg';
import COL from '../../images/COL.svg';
import CWS from '../../images/CWS.svg';
import DET from '../../images/DET.svg';
import HOU from '../../images/HOU.svg';
import KC from '../../images/KC.svg';
import LAD from '../../images/LAD.svg';
import LAA from '../../images/LAA.svg';
import MIA from '../../images/MIA.svg';
import MIL from '../../images/MIL.svg';
import MIN from '../../images/MIN.svg';
import NYM from '../../images/NYM.svg';
import NYY from '../../images/NYY.svg';
import OAK from '../../images/OAK.svg';
import PHI from '../../images/PHI.svg';
import PIT from '../../images/PIT.svg';
import SDP from '../../images/SDP.svg';
import SEA from '../../images/SEA.svg';
import SFG from '../../images/SFG.svg';
import STL from '../../images/STL.svg';
import TBR from '../../images/TBR.svg';
import TEX from '../../images/TEX.svg';
import TOR from '../../images/TOR.svg';
import WSH from '../../images/WSH.svg';

const logos = {
  ARI,
  ATL,
  BAL,
  BOS,
  CHC,
  CIN,
  CLE,
  COL,
  CWS,
  DET,
  HOU,
  KC,
  LAD,
  LAA,
  MIA,
  MIL,
  MIN,
  NYM,
  NYY,
  OAK,
  PHI,
  PIT,
  SDP,
  SEA,
  SFG,
  STL,
  TBR,
  TEX,
  TOR,
  WSH,
};

// Timer component showing approximately how much time remains for the current pick
// Also shows the next pick and when the next pick is
function TimerCard() {
  const currentPick = useSelector(selectCurrentPickInRound);
  const currentRound = useSelector(selectCurrentRound);
  const metPicks = useSelector(selectMetPicks);
  const rounds = useSelector(selectRounds);
  const roundsFlat = useSelector(selectInitRounds);
  const currentOverall = useSelector(selectCurrentOverall);
  const drafted = useSelector(selectDrafted);
  const [pickTime, setPickTime] = useState(roundsFlat[currentOverall + 1]?.pickTimeInSeconds);
  const hasPickOccured = useSelector(selectHasPickOccured);

  // Get round name
  function getRoundName(round) {
    try {
      if (!!round.roundCode && Number.isNaN(round.roundCode)) {
        return round.roundCode;
      } if (round.roundCode) {
        return round.roundCode;
      }
      return '';
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return round;
  }

  // Format dollar amounts as K or M
  function dollarFormatter(amount) {
    if (amount > 1e6) {
      return `${amount / 1e6}M`;
    } if (amount > 1e3) {
      return `${amount / 1e3}K`;
    }
    return amount;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = Math.floor((((drafted[drafted.length - 1]?.pickTimestamp || 0)
        + ((roundsFlat[currentOverall + 1]?.pickTimeInSeconds || 0) * 2500)) - Date.now()) / 1000);

      if (remainingTime > 0) {
        setPickTime(remainingTime);
      } else if (remainingTime <= 0) {
        setPickTime(0);
      }
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [pickTime, hasPickOccured]);

  if (rounds.length > 1) {
    let metsNextPick = 0;
    let previousName = '';
    let currentRoundFinal = 0;
    let currentPickFinal = 0;
    let metsNextPickObj;
    let timerColor;
    let approxTime;

    if (currentRound > 0 && currentOverall > 0) {
      currentRoundFinal = currentRound - 1;
      currentPickFinal = currentPick;
      previousName = drafted[drafted.length - 1]['playerInfo.playerName'];
      for (let i = 0; i < metPicks.length; i += 1) {
        if (currentOverall < metPicks[i]) {
          metsNextPick = metPicks[i] - currentOverall - 1;

          metsNextPickObj = roundsFlat.find((pick) => metPicks[i] === pick.overallPickNumber);
          // quick and dirty approx time to next met pick
          approxTime = metsNextPick * metsNextPickObj.pickTimeInSeconds;
          timerColor = 255 - approxTime;
          if (timerColor < 0) {
            timerColor = 0;
          }
          break;
        }
      }
    }
    let currentLogo = '';
    // let onDeckLogo = '';
    let valueFinal = 0;
    // let onDeck;

    if (currentRoundFinal < rounds.length && currentPickFinal < rounds[currentRoundFinal].length) {
      currentLogo = logos[teams[roundsFlat[currentOverall]?.orgId]];
      // onDeckLogo = logos[teams[rounds[currentRoundFinal][currentPickFinal]?.orgId]
      // || teams[rounds[currentRoundFinal][0]?.orgId]];
      valueFinal = roundsFlat[currentOverall]?.signingBonusValue;
    }

    // Next team's previous pick
    if (currentRoundFinal < rounds.length) {
      // onDeck = rounds[currentRoundFinal][currentPickFinal + 1]
      // || rounds[currentRoundFinal + 1][0];
    } else {
      // onDeck = 'END';
    }

    const lastPlayer = drafted?.[drafted.length - 1];
    const overallPick = lastPlayer?.overallPick;
    const lastPick = roundsFlat.find((pick) => overallPick === pick.overallPickNumber);
    const lastLogo = logos[teams?.[lastPick?.orgId]];

    return (
      <Card id="ribbon" className="timerCard">
        <span className="timerCardInner">
          <span className="timerCountdown">
            {Math.floor(pickTime / 60).toString().padStart(2, '0')}
            :
            {(pickTime % 60).toString().padStart(2, '0')}
          </span>
          <span>
            <img className="timerLogo" src={currentLogo} alt="timerLogo" />
            {' '}
            {roundsFlat[currentOverall]?.roundCode}
            -
            {roundsFlat[currentOverall]?.overallPickNumber}
            : ON THE CLOCK ($
            {dollarFormatter(valueFinal)}
            )
          </span>

          <span>
            <img className="timerLogo" src={lastLogo} alt="timerLogo" />
            {' '}
            PREV PICK:
            {' '}
            {getRoundName(lastPick)}
            -
            {lastPick?.overallPickNumber}
            :
            {' '}
            {previousName}
            {' '}
            ($
            {dollarFormatter(lastPick?.signingBonusValue)}
            )
          </span>
        </span>
        <span className="timerMets">
          <img
            className="timerLogo"
            src={NYM}
            alt="timerLogo"
          />
          {' '}
          OUR NEXT PICK IN:
          {' '}
          {metsNextPick}
          {' '}
          PICKS (
          {metsNextPickObj?.roundCode}
          -
          {metsNextPickObj?.overallPickNumber}
          : $
          {dollarFormatter(metsNextPickObj?.signingBonusValue)}
          )
          <span className="timerApprox" style={{ color: `rgb(${timerColor},0,0)` }}>
            (
            ~
            {approxTime}
            {' '}
            sec
            )
          </span>
        </span>
      </Card>
    );
  }
}

export default TimerCard;
