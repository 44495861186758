import { createSlice } from '@reduxjs/toolkit';

export const ebisSlice = createSlice({
  name: 'ebis',
  initialState: {
    bearer: '',
  },
  reducers: {
    populateBearer: (state, action) => ({
      ...state,
      bearer: action.payload,
    }),
  },
});

export const selectBearer = (state) => state.ebis.bearer;

export const { populateBearer } = ebisSlice.actions;

export default ebisSlice.reducer;
