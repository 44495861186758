import React, { useEffect } from 'react';
import '../../index.css';
import { Row, Col } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import TimerCard from '../TimerCard/timerCard';
import PlayerFocusCard from '../PlayerFocus/PlayerFocusCard';
import PlayerTableCard from '../PlayerTable/PlayerTableCard';
import {
  populatePlayers,
  selectStatus,
  selectDrafted,
  initializeDraftedPlayers,
  hasDraftAdvanced,
} from '../../Redux/playersSlice';
import {
  populateInitialRounds, selectCurrentOverall, populateRounds,
  populateMetPicks,
  selectMode,
  initializeCurrentDraftState,
} from '../../Redux/roundsSlice';
import DraftBoardContainerCard from '../DraftBoard/DraftBoardContainerCard';
import { populateBearer, selectBearer } from '../../Redux/ebisSlice';
import FilterBar from '../FilterBar/FilterBar';
import Ticker from '../Ticker/Ticker';

// The main component of the draft app, most components outside of the header build off of this
function MainSection() {
  const dispatch = useDispatch();
  const loadStatus = useSelector(selectStatus);
  const drafted = useSelector(selectDrafted);
  const mode = useSelector(selectMode);
  const bearer = useSelector(selectBearer);
  const currentOverall = useSelector(selectCurrentOverall);

  // Check to see if a new pick has come in
  async function getRecentPick() {
    const myHeaders = new Headers();
    if (bearer !== '') {
      myHeaders.append('Authorization', `Bearer ${bearer}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow',
      };

      fetch(`https://ebis.mlb.com/ebis2-rule4-services/Rule4/DraftDay/DraftPick/${(currentOverall + 1).toString()}/DraftedPlayer`, requestOptions)
        .then((result) => result.text())
        .then((res) => dispatch(hasDraftAdvanced(res)))
        // eslint-disable-next-line no-console
        .catch((error) => console.log('error', error));
    }
  }

  // Format rounds for ease of use
  function formatRoundsDb(rounds) {
    const metsArray = [];
    const roundsArray = [];
    let roundsSubArray = [];
    let currRound = '1';
    for (let i = 0; i < rounds.length; i += 1) {
      if (currRound !== rounds[i].roundCode) {
        currRound = rounds[i].roundCode;
        roundsArray.push(roundsSubArray);
        roundsSubArray = [];
      }
      if (rounds[i].orgId === 20) {
        metsArray.push(rounds[i].overallPickNumber);
      }
      roundsSubArray.push(rounds[i]);
    }
    roundsArray.push(roundsSubArray);
    dispatch(populateInitialRounds(rounds));
    dispatch(populateRounds(roundsArray));
    dispatch(populateMetPicks(metsArray));
  }
  async function getAllRoundsDb() {
    await axios.get('https://dev.apple-picker.nymets.com/api/rounds/')
      .then((response) => formatRoundsDb(response.data));
  }
  async function getAllPlayersDb() {
    await axios.get('https://dev.apple-picker.nymets.com/api/rounds/players/')
      .then((response) => dispatch(populatePlayers(response.data)));
  }
  async function getAllDraftedPlayersDb() {
    await axios.get('https://dev.apple-picker.nymets.com/api/rounds/drafted/')
      .then((response) => dispatch(initializeDraftedPlayers(response.data)));
  }
  // Could have use from draft replays
  /*
  async function getAllDraftedPlayersLimitDb(pick) {
    await axios.get(`https://dev.apple-picker.nymets.com/api/rounds/drafted/${pick.toString()}`)
      .then((response) => dispatch(populateDraftedPlayers(response.data)));
  }
  */
  async function CreateEbisConnection() {
    const myHeaders = new Headers();

    myHeaders.append('Authorization', 'Basic ZWJpc2xpbmtfZGV2QG55bWV0cy5jb206NDEtc3RpY2stRlJPTS1ib3R0b20tODc=');
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

    const urlencoded = new URLSearchParams();
    urlencoded.append('username', process.env.REACT_APP_EBIS_USERNAME);
    urlencoded.append('password', process.env.REACT_APP_EBIS_PASSWORD);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow',
    };

    await fetch('https://ebislink.mlb.com/token/access', requestOptions)
      .then((response) => response.json())
      .then((result) => dispatch(populateBearer(result.access_token)))
      // eslint-disable-next-line no-console
      .catch((error) => console.log('error', error));
  }

  useEffect(() => {
    let interval = '';
    if (loadStatus === 'UNLOADED') {
    // Populate all data on startup
      CreateEbisConnection();
      getAllPlayersDb();
      getAllRoundsDb();
      getAllDraftedPlayersDb();
      // dispatch(initializeCurrentDraftState(drafted));
    // eslint-disable-next-line brace-style
    }
    // DB DRAFT ONLY
    /*
    else if(loadStatus === 'LOADED' && loadStatusPriorPicks === 'UNLOADED')
    {
      dispatch(initializeCurrentDraftState(drafted));
    }
    */
    else {
      // Check for new pick every 5 seconds
      interval = setInterval(() => {
        // DB DRAFT ONLY
        // dispatch(hasDraftAdvanced());
        // getAllDraftedPlayersLimitDb(currentOverall + 1);
        // dispatch(incrementCurrentPick());

        // LIVE API DRAFT ONLY
        getRecentPick();
        getAllDraftedPlayersDb();
        dispatch(initializeCurrentDraftState(drafted));
      }, 5000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [currentOverall, loadStatus, drafted]);

  if (mode === 'board') {
    return (
      <>
        <FilterBar />
        <TimerCard />
        <DraftBoardContainerCard />
      </>
    );
  }

  return (
    <>
      <Row gutter={[0]}>
        <Col span={24}>
          <FilterBar />
        </Col>
      </Row>
      <Row gutter={[0]}>
        <Col span={24}>
          <PlayerFocusCard />
        </Col>
      </Row>
      <Row gutter={[0]}>
        <Col span={24}>
          <PlayerTableCard />
        </Col>
        <Col span={0.5} />
      </Row>
      <Row gutter={[0]}>
        <Col span={24}>
          <TimerCard />
        </Col>
      </Row>
      <Ticker />
    </>
  );
}

export default MainSection;
