import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { selectFocus } from '../../Redux/focusSlice';
import './PlayerMagnetHitter.css';

// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const, no-unused-vars
let yrTPA, yrRPG, yrBB, yrK, yrHR, yrGB, yrISO, yrAVG, yrOBP, yrSLG, yrOPS = '';
// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const, no-unused-vars
let carTPA, carRPG, carBB, carK, carHR, carGB, carISO, carAVG, carOBP, carSLG, carOPS = '';
// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const, no-unused-vars
let bip, ppa, ms, ch, ninetyfive, oneohfive, adjrvc, adjk, adjbb = '';
// eslint-disable-next-line one-var, one-var-declaration-per-line, prefer-const, no-unused-vars
let hit, pow, def, run, arm, ath, mkp, med, eyes = '';

// Conditional formatting, green is good, yellow is ok, red is bad
function conditionalFormatting(input, low, high) {
  if (input === null) {
    return 'statUnconditionalHitter';
  }
  if (low < high) {
    if (input <= low) {
      return 'redStyleHitter';
    }
    if (input > low && input <= high) {
      return 'yellowStyleHitter';
    }
    if (input > high) {
      return 'greenStyleHitter';
    }
  } else if (low > high) {
    if (input > high) {
      return 'redStyleHitter';
    }
    if (input <= high && input > low) {
      return 'yellowStyleHitter';
    }
    if (input <= low) {
      return 'greenStyleHitter';
    }
  }
  return 'statUnconditionalHitter';
}
// Replicates the magnets for hitters used by scouting
function PlayerMagnetHitter() {
  const focus = useSelector(selectFocus);
  const magnetVars = [
    {
      stat: yrRPG,
      input: focus['22rpg'],
      low: 4,
      high: 7,
    },
    {
      stat: yrBB,
      input: focus.BB,
      low: 8,
      high: 11,
    },
    {
      stat: yrK,
      input: focus.K,
      low: 20,
      high: 12,
    },
    {
      stat: yrGB,
      input: focus['22gb'],
      low: 55,
      high: 40,
    },
    {
      stat: yrISO,
      input: focus['22iso'],
      low: 0.1,
      high: 0.2,
    },
    {
      stat: yrAVG,
      input: focus['22avg'],
      low: 0.25,
      high: 0.3,
    },
    {
      stat: yrOBP,
      input: focus['22obp'],
      low: 0.33,
      high: 0.4,
    },
    {
      stat: yrSLG,
      input: focus['22slg'],
      low: 0.35,
      high: 0.45,
    },
    {
      stat: yrOPS,
      input: focus['22ops'],
      low: 0.7,
      high: 0.85,
    },
    {
      stat: carRPG,
      input: focus.Carrpg,
      low: 4,
      high: 7,
    },
    {
      stat: carBB,
      input: focus.Carbb,
      low: 8,
      high: 11,
    },
    {
      stat: carK,
      input: focus.Cark,
      low: 20,
      high: 12,
    },
    {
      stat: carGB,
      input: focus.Cargb,
      low: 55,
      high: 40,
    },
    {
      stat: carISO,
      input: focus.Cariso,
      low: 0.1,
      high: 0.2,
    },
    {
      stat: carAVG,
      input: focus.Caravg,
      low: 0.25,
      high: 0.3,
    },
    {
      stat: carOBP,
      input: focus.Carobp,
      low: 0.33,
      high: 0.4,
    },
    {
      stat: carSLG,
      input: focus.Carslg,
      low: 0.35,
      high: 0.45,
    },
    {
      stat: carOPS,
      input: focus.Carops,
      low: 0.7,
      high: 0.85,
    },
    {
      stat: ppa,
      input: focus.PPA,
      low: 3,
      high: 4.5,
    },
    {
      stat: ms,
      input: focus.MS,
      low: 30,
      high: 20,
    },
    {
      stat: ch,
      input: focus.CHS,
      low: 35,
      high: 25,
    },
    {
      stat: ninetyfive,
      input: focus['95+'],
      low: 25,
      high: 35,
    },
    {
      stat: oneohfive,
      input: focus['105+'],
      low: 3,
      high: 10,
    },
    {
      stat: hit,
      input: focus.HA,
      low: 45,
      high: 55,
    },
    {
      stat: pow,
      input: focus.RP,
      low: 45,
      high: 55,
    },
    {
      stat: def,
      input: focus.FA,
      low: 45,
      high: 55,
    },
    {
      stat: run,
      input: focus.RS,
      low: 45,
      high: 55,
    },
    {
      stat: ath,
      input: focus.Ath,
      low: 45,
      high: 55,
    },
    {
      stat: mkp,
      input: focus.Mkp,
      low: 45,
      high: 55,
    },
    {
      stat: eyes,
      input: focus.Eye,
      low: 45,
      high: 55,
    },
    {
      stat: adjrvc,
      input: focus['Adj.2'],
      low: 0.1,
      high: 0.16,
    },
    {
      stat: adjk,
      input: focus.Adj,
      low: 20,
      high: 12,
    },
    {
      stat: adjbb,
      input: focus['Adj.1'],
      low: 8,
      high: 11,
    },
  ];
  magnetVars.forEach((magnetVar) => {
    // eslint-disable-next-line no-param-reassign
    magnetVar.stat = conditionalFormatting(magnetVar.input, magnetVar.low, magnetVar.high);
  });
  // console.log('magnetVars',magnetVars);
  return (
    <Row>
      <Col span={12}>
        <Row>
          <Col span={2} className="statNameHitter">YR</Col>
          <Col span={2} className="statNameHitter">TPA</Col>
          <Col span={2} className="statNameHitter">RPG</Col>
          <Col span={2} className="statNameHitter">BB%</Col>
          <Col span={2} className="statNameHitter">K%</Col>
          <Col span={2} className="statNameHitter">HR</Col>
          <Col span={2} className="statNameHitter">GB%</Col>
          <Col span={2} className="statNameHitter">ISO</Col>
          <Col span={2} className="statNameHitter">AVG</Col>
          <Col span={2} className="statNameHitter">OBP</Col>
          <Col span={2} className="statNameHitter">SLG</Col>
          <Col span={2} className="statNameHitter">OPS</Col>
        </Row>
        <Row>
          <Col span={2} className="statUnconditionalHitter">22</Col>
          <Col span={2} className="statUnconditionalHitter">{focus.PA || '--'}</Col>
          <Col span={2} className={magnetVars[0].stat}>{focus['22rpg'] || '--'}</Col>
          <Col span={2} className={magnetVars[1].stat}>{(focus.BB * 100)?.toFixed(1) || '--'}</Col>
          <Col span={2} className={magnetVars[2].stat}>{(focus.K * 100)?.toFixed(1) || '--'}</Col>
          <Col span={2} className="statUnconditionalHitter">{focus['22hr'] || '--'}</Col>
          <Col span={2} className={magnetVars[3].stat}>{focus['22gb'] || '--'}</Col>
          <Col span={2} className={magnetVars[4].stat}>{focus['22iso']?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[5].stat}>{focus['22avg']?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[6].stat}>{focus['22obp']?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[7].stat}>{focus['22slg']?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[8].stat}>{focus['22ops']?.toFixed(3) || '--'}</Col>
        </Row>
        <Row>
          <Col span={2} className="statUnconditionalHitter">Car</Col>
          <Col span={2} className="statUnconditionalHitter">{focus.Cartpa || '--'}</Col>
          <Col span={2} className={magnetVars[9].stat}>{focus.Carrpg || '--'}</Col>
          <Col span={2} className={magnetVars[10].stat}>{focus.Carbb || '--'}</Col>
          <Col span={2} className={magnetVars[11].stat}>{focus.Cark || '--'}</Col>
          <Col span={2} className="statUnconditionalHitter">{focus.Carhr || '--'}</Col>
          <Col span={2} className={magnetVars[12].stat}>{focus.Cargb || '--'}</Col>
          <Col span={2} className={magnetVars[13].stat}>{focus.Cariso?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[14].stat}>{focus.Caravg?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[15].stat}>{focus.Carobp?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[16].stat}>{focus.Carslg?.toFixed(3) || '--'}</Col>
          <Col span={2} className={magnetVars[17].stat}>{focus.Carops?.toFixed(3) || '--'}</Col>
        </Row>
      </Col>
      <Col span={1} />
      <Col span={11}>
        <Row>
          <Col span={2} className="statNameHitter">BIP</Col>
          <Col span={2} className="statNameHitter">PPA</Col>
          <Col span={2} className="statNameHitter">Ms%</Col>
          <Col span={2} className="statNameHitter">Ch%</Col>
          <Col span={2} className="statNameHitter">95+</Col>
          <Col span={2} className="statNameHitter">105+</Col>
          <Col span={2} className="statNameHitter">Adj RVC</Col>
          <Col span={2} className="statNameHitter">Adj K%</Col>
          <Col span={2} className="statNameHitter">Adj BB%</Col>
        </Row>
        <Row>
          <Col span={2} className={magnetVars[17].stat}>{focus.BIP || '--'}</Col>
          <Col span={2} className={magnetVars[18].stat}>{focus.PPA || '--'}</Col>
          <Col span={2} className={magnetVars[19].stat}>{focus.MS || '--'}</Col>
          <Col span={2} className={magnetVars[20].stat}>{focus.CHS || '--'}</Col>
          <Col span={2} className={magnetVars[21].stat}>{focus['95+'] || '--'}</Col>
          <Col span={2} className={magnetVars[22].stat}>{focus['105+'] || '--'}</Col>
          <Col span={2} className={magnetVars[30].stat}>{(focus['Adj.2'] * 100)?.toFixed(1) || '--'}</Col>
          <Col span={2} className={magnetVars[31].stat}>{(focus.Adj * 100)?.toFixed(1) || '--'}</Col>
          <Col span={2} className={magnetVars[32].stat}>{(focus['Adj.1'] * 100)?.toFixed(1) || '--'}</Col>
        </Row>
        <Row>
          <Col span={2} className="statNameHitter">Hit</Col>
          <Col span={2} className="statNameHitter">Pow</Col>
          <Col span={2} className="statNameHitter">Def</Col>
          <Col span={2} className="statNameHitter">Run</Col>
          <Col span={2} className="statNameHitter">Arm</Col>
          <Col span={2} className="statNameHitter">Ath</Col>
          <Col span={2} className="statNameHitter">Mkp</Col>
          <Col span={2} className="statNameHitter">Med</Col>
          <Col span={2} className="statNameHitter">Eyes</Col>
        </Row>
        <Row>
          <Col span={2} className={magnetVars[23].stat}>{focus.HA?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[24].stat}>{focus.RP?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[25].stat}>{focus.FA?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[26].stat}>{focus.RS?.toFixed(0) || '--'}</Col>
          <Col span={2} className="statUnconditionalHitter">{focus.AS?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[27].stat}>{focus.Ath?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[28].stat}>{focus.Mkp?.toFixed(0) || '--'}</Col>
          <Col span={2} className="statUnconditionalHitter">{focus.Medical?.toFixed(0) || '--'}</Col>
          <Col span={2} className={magnetVars[29].stat}>{focus.Eye?.toFixed(0) || '--'}</Col>
        </Row>
      </Col>
    </Row>
  );
}

export default PlayerMagnetHitter;
